<script>

	import { onMount, onDestroy, tick } from 'svelte';
	import { fly } from 'svelte/transition';

	import CheckedIn from './Attendee/CheckedIn.svelte';
	import EditBadge from './Attendee/EditBadge.svelte';
	import Panel from './ui/Panel.svelte';
	import ButtonGroup from './ui/ButtonGroup.svelte';
	import Button from './ui/Button.svelte';
	import Note from './ui/Note.svelte';
	import Actions from './Attendee/Actions.svelte';
	import DisplayField from './ui/DisplayField.svelte';

	import {
		screen,
		attendee,
		bigError,
		busy,
		event,
		settings,
		offline,
		deviceRef,
		modal,
		templateHashtags,
		message,
		isNarrowScreen,
		displayLang,
		countryOpts
	} from './lib/stores.js';

	import { postServerData } from './lib/prelude.js';
	import { db } from "./lib/db.js";
	import { isYetToCome, showDate } from "./lib/dt.js";
	import { showSuccess } from "./lib/success.js";
	import { isVIP } from "./lib/vips.js";
	import { isDesktop } from './lib/device.js';

	let done = false;
	let already = false;
	let outOfWindow = false;
	let canCheckIn = true;
	let ticketType;
	let showLog = false;
	let vip = null;
	let editPanel = false;

	let containerWidth;
	let textWidth;
	let textElement;

	function setAttendee() {

		already = false;
		outOfWindow = false;
		canCheckIn = true;

		vip = isVIP($attendee.ref);

		for (const tt of $event?.ticketTypes) {
			if ($attendee.ticketType == tt.ref) {
				ticketType = tt;
				break;
			}
		}

		console.log('attendee', $attendee);

		if ($attendee.status == 'checked-in') {
			already = true;
		}

		if (already) {
			canCheckIn = false;
			if (!$settings.staffed) {
				done = true;
			}
		}

		if ($attendee.isVirtual) {
			canCheckIn = false;
		}

		if (!already) {

			if (ticketType.validFrom) {
				if (isYetToCome(ticketType.validFrom)) {
					// console.log('ticketType.validFrom', ticketType.validFrom, isYetToCome(ticketType.validFrom));
					outOfWindow = true;
					canCheckIn = false;
				}
			}

			if (ticketType.validTo) {
				if (!isYetToCome(ticketType.validTo)) {
					// console.log('ticketType.validTo', ticketType.validTo, isYetToCome(ticketType.validTo));
					outOfWindow = true;
					canCheckIn = false;
				}
			}

		}

		// TODO: mustPay

		if (!$settings.staffed) {
			if (outOfWindow) {
				$message = {
					message: 'You’re currently outside of the check-in window specified for the ' + ticketType?.name?.EN + ' ticket type.',
					details: [
						'Please ask a member of staff for assistance.'
					]
				};
			} else if (vip && !vip.selfService) {
				canCheckIn = false;
				const attendeeMessage = vip.attendeeMessage ? vip.attendeeMessage : 'Please ask a member of staff for assistance.';
				$message = {
					message: attendeeMessage
				};
			}
		}

		if ($attendee.instaCheckin) {
			// Only set by walk-in registrations
			if ($settings.staffed || !outOfWindow) {
				done = true;
				checkin();
			}
		}

	}

	const checkin = async () => {

		let checkedin = false;

		$busy = true;

		if ($offline) {

			const dt = new Date().toISOString().replace('Z',''); // UTC

			db.offline.add({
				action: 'checkin',
				mode: $settings.mode,
				dt: dt,
				event: $event.ref,
				attendee: $attendee.ref
			});

			checkedin = true;

		} else {

			console.log('Checking in', $attendee.ref, $settings.printer);

			const checkinResponse = await postServerData('/checkin', {
				attendeeRef: $attendee.ref,
				deviceName: $settings.deviceName,
				templateRef: $attendee.badgeTemplate,
				printer: $settings.printer
			});

			if (!$bigError) {
				if (checkinResponse.error) {
					$bigError = 'We were unable to check in this attendee.';
					$busy = false;
				} else {
					checkedin = true;
				}
			}

		}

		if (checkedin) {

			await db.attendees.update($attendee.ref, {
				status: 'checked-in'
			});

			if ($settings.staffed) {
				if ($offline && $settings.printer && isDesktop()) {
					showSuccess(async function(){
						$attendee = await db.attendees.get($attendee.ref);
						$busy = false;
					});
				} else {
					showSuccess(function(){
						$attendee = null;
						$screen = null;
					});
				}
			} else {
				$busy = false;
				done = true;
			}

		}

	}

	async function checkTextWidth() {
			// console.log('checkTextWidth', containerWidth, textWidth);
		await tick();
		if (textWidth > containerWidth) {
			let style = window.getComputedStyle(textElement, null).getPropertyValue('font-size');
			let fontSize = parseFloat(style);
			// console.log('checkTextWidth', containerWidth, textWidth, fontSize);
			fontSize--;
			textElement.style.fontSize = fontSize + 'px';
		}
	}

	$: if (textWidth || containerWidth) {
		checkTextWidth();
	}

	$: if ($attendee) {
		setAttendee();
	}

</script>

<style>
	.attendee {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}

	.details {
		flex: 1 1 auto;
		overflow-y: auto;
		width: 100vw;
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		padding: 0 6%;
		box-sizing: border-box;
		background:
			linear-gradient(var(--bgColor) 30%, transparent),
			linear-gradient(transparent, var(--bgColor) 70%) 0 100%,
			linear-gradient(var(--shadowColor), transparent),
			linear-gradient(transparent, var(--shadowColor)) 0 100%;
		background-repeat: no-repeat;
		background-color: var(--bgColor);
		background-size: 100% 40px, 100% 40px, 100% 1rem, 100% 1rem;
		background-attachment: local, local, scroll, scroll;
		display: flex;
		align-items: center;
	}
	.details.staffed {
		align-items: flex-start;
	}

	.constrained {
		position: relative;
		margin: 0 auto;
		width: 100%;
		max-width: 600px;
		padding: 0;
		box-sizing: border-box;
	}

	.avatar {
		flex: 0 0 8rem;
	}

	.avatar img {
		border-radius: 100em;
		width: 7.4rem;
	}

	@media (max-width: 600px) {
		.avatar img {
			width: 5rem;
		}
	}

	.basicData {
		padding: 2rem 2px 4rem 2px;
		/*font-size: 4rem;*/
		font-size: clamp(2rem, 4rem, 12vw);
		color: var(--textColor);
		display: flex;
		gap: 0.5rem 2rem;
		text-align: left;
		line-height: 1.1;
		max-width: 100%;
	}

	@media (max-width: 600px) {
		.basicData {
			flex-wrap: wrap;
		}
	}

	.basicData > div {
		flex: 0 1 auto;
		overflow: hidden;
	}

	.basicData > div > div {
		max-width: 100%;
	}

	.basicData > div > div > p {
		min-width: min-content;
	}

	.basicData .o {
		display: block;
		font-size: 0.5em;
		line-height: 1.4;
		margin-top: 0.6rem;
	}

	.staffed .basicData {
		padding: 3rem 0 2rem 0;
	}

	.data > :global(p) {
		margin: 0.8rem 0;
		display: flex;
		gap: 2rem;
		text-align: left;
	}

	.data > :global(p > span) {
		color: var(--labelColor);
		width: 8rem;
		font-size: 0.9rem;
		line-height: 1.7rem;
		padding-top: 0.1rem;
	}

	.data > :global(p > strong) {
		color: var(--textColor);
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	@media (max-width: 600px) {
		.staffed .basicData {
			padding-bottom: 1.6rem;
		}
		.data > :global(p > span) {
			width: 7rem;
		}
	}

</style>

{#if done}
	<CheckedIn {already}/>
{:else}
	{#if $attendee}
		<div class="attendee" in:fly={{ duration: 300, y: 10, delay: 200 }} out:fly|local={{ duration: 200, y: 10 }}>

			<div class="details" class:staffed={$settings.staffed}>
				<div class="constrained">

					{#if $settings.staffed}

						{#if already}
							<Note type="info">
								This attendee is already checked-in
							</Note>
						{/if}

						{#if (!already && outOfWindow)}
							<Note type="alert" >
								This attendee is outside of the check-in window specified for the {ticketType?.name?.EN} ticket type
							</Note>
						{/if}

						{#if vip && vip.staffNotes}
							<Note type="vip">
								{vip.staffNotes}
							</Note>
						{/if}

					{/if}

					<div class="basicData">

						{#if $settings.staffed && $attendee.avatar}
							<p class="avatar">
								<img
									src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{$attendee.avatar}"
									crossorigin="anonymous"
									alt="{$attendee.n}"
								/>
							</p>
						{/if}

						<div>
							<div bind:clientWidth={containerWidth}>
								<p bind:this={textElement} bind:clientWidth={textWidth}>
									<strong>{$attendee.f} {$attendee.l}</strong>
									{#if $attendee.o}
										<span class="o">{$attendee.o}</span>
									{/if}
								</p>
							</div>
						</div>

					</div>

					{#if $settings.staffed}
						<div class="data">

							<p>
								<span>Ticket type</span>
								<strong>{ticketType?.name?.EN}</strong>
							</p>

							{#if $attendee.attendeeCode}
								<p>
									<span>Attendee ID</span>
									<strong>{$attendee.attendeeCode}</strong>
								</p>
							{/if}

							{#if $attendee.badgeTemplate && $templateHashtags[$attendee.badgeTemplate]}
								{#each $templateHashtags[$attendee.badgeTemplate] as field }
									<DisplayField {field}/>
								{/each}
							{/if}

							{#if $attendee.tags && $attendee.tags.length}
								<p>
									<span>Tags</span>
									<strong>{$attendee.tags.join(', ')}</strong>
								</p>
							{/if}

						</div>
					{/if}

				</div>
			</div>

			{#if $settings.staffed}
				<div class="constrained">
					<Actions {checkin} {already} bind:editPanel/>
				</div>
			{:else}
				{#if canCheckIn}
					<div class="constrained">
						<ButtonGroup>
							<Button on:click={checkin} label="Confirm" wide={true}>
								<svg viewBox="0 0 80 80"><path d="M28.8 59.6l-12.6-12.6 4.3-4.2 8.3 8.3 30.7-30.7 4.3 4.2z"/></svg>
							</Button>
							{#if $settings.allowEdits && $settings.printer}
								<Button ghost={true} on:click={() => { editPanel = true }} label="Edit badge">
									<svg viewBox="0 0 80 80"><path d="M62 63c0 1.1-.9 2-2 2h-46c-1.1 0-2-.9-2-2v-36c0-1.1.9-2 2-2h33l4-4h-10v-6c0-2.2-1.8-4-4-4s-4 1.8-4 4v6h-21c-2.2 0-4 1.8-4 4v40c0 2.2 1.8 4 4 4h50c2.2 0 4-1.8 4-4v-25l-4 4v19zM45.7 54.6l-12.7 3.3c-.6.1-1.1 0-1.5-.4-.4-.4-.6-1-.4-1.5l3.3-12.6 11.3 11.2zM58.3 19.4c3.1-3.1 8.2-3.1 11.3 0 3.1 3.1 3.1 8.2 0 11.3l-11.3-11.3zM66.7 33.6l-11.3-11.3-18.2 18.1 4.3 4.3 15.3-15.4 2.9 2.8-15.4 15.4 4.3 4.2z"/></svg>
								</Button>
							{/if}
							<Button ghost={true} on:click={() => { $attendee = null; $screen = null; }} label="Cancel">
								<svg viewBox="0 0 80 80"><path d="M24.4 20.2l-4.2 4.2 15.6 15.6-15.6 15.6 4.2 4.2 15.6-15.6 15.5 15.6 4.3-4.3-15.6-15.5 15.6-15.6-4.2-4.2-15.6 15.6z"/></svg>
							</Button>
						</ButtonGroup>
					</div>
				{/if}
			{/if}

		</div>
	{/if}
{/if}

{#if editPanel}
	<Panel title="Edit badge" on:close={() => { editPanel = false }}>
		<EditBadge bind:active={editPanel} />
	</Panel>
{/if}
