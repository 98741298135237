<script>

	import { onMount, onDestroy } from 'svelte';
	import { fly } from 'svelte/transition';

	import cssVars from 'svelte-css-vars';

	import ButtonGroup from './ui/ButtonGroup.svelte';
	import Button from './ui/Button.svelte';

	import { event, showSettings, screen, showRegform, settings, splash, unbranded, offline } from './lib/stores.js';
	import { walkInsAvailable } from "./lib/ticketTypes.js";

	let containerHeight = 0;
	let textHeight = 0;
	let mh = 0;

	const flex = {
		left: 'flex-start',
		center: 'center',
		right: 'flex-end'
	};

	console.log('(splash) event',$event);
	console.log('(splash) splash',$splash);

	let styles = {
		textColor: $event.theme.colors[$splash.textColor],
		linkColor: $event.theme.colors[$splash.linkColor],
		headingColor: $event.theme.colors[$splash.headingColor],
		alignment: $splash.alignment,
		flexAlignment: flex[$splash.alignment],
		verticalAlignment: $splash.verticalAlignment,
		fgImageSize: $splash.fgImageSize
	};

	if ($splash.fgImage && ($splash.fgImagePresentation == 'tinted')) {
		styles.fgTintColor = $event.theme.colors[$splash.fgTintColor];
		styles.mask = 'url("https://cdn.attendzen.io/' + $event.accountRef + '/mask_' + $splash.fgImage.filename.replace(/\.svg$/,'.png') + '")';
	}

	function setImageMaxHeight() {
		mh = containerHeight - textHeight;
	}

	function setScreen() {
		if ($settings.qr) {
			$screen = 'qr';
		} else {
			$screen = 'search';
		}
	}

	function setUnbranded() {
		$unbranded = !$splash.hasBranding;
	}

	onDestroy(() => {
		$unbranded = false;
	});

	$: if ($splash) {
		setUnbranded();
	}

	$: setImageMaxHeight(containerHeight, textHeight);

</script>

<style>

	.splash {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 1rem;
	}
	.splash_inner {
		flex: 1 0 auto;
		overflow: hidden;
		position: relative;
	}
	.splash_inner > div {
		position: absolute;
		inset: 0 0 1rem 0;
		display: flex;
		flex-direction: column;
		justify-content: var(--verticalAlignment);
		gap: 2rem;
	}

	.actions {
		margin-bottom: 1rem;
	}

	.splash_inner .fgImage {
		flex: 0 1 auto;
		display: flex;
		justify-content: var(--flexAlignment, flex-start);
	}
	.splash_inner .fgImage > div  {
		flex: 0 1 auto;
		overflow: hidden;
		max-width: calc(1% * var(--fgImageSize, 50));
		position: relative;
	}
	.splash_inner .fgImage img {
		width: 100%;
		max-height: calc(var(--mh) - 2rem);
	}
	.splash_inner .textcontent {
		position: relative;
		flex: 0 0 auto;
	}

	@supports (-webkit-mask-image: url("")) or (mask-image: url("")) {
		.splash :global(.splash_inner.logo.tinted .fgImage > div:after) {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 50% 50%;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 50% 50%;
			background: var(--fgTintColor);
		}
		.splash :global(.splash_inner.logo.tinted img) {
			visibility: hidden;
		}
	}

	/*@keyframes shine {
		0% {
			transform: translateX(-100%) skew(-30deg);
		}
		20% {
			transform: translateX(100%) skew(-30deg);
		}
		100% {
			transform: translateX(100%) skew(-30deg);
		}
	}*/

	@keyframes pulse {
		0% {
			transform: scale(1);
			opacity: 1;
		}
		5% {
			opacity: 1;
		}
		20% {
			transform: scaleX(1.15) scaleY(1.6);
			opacity: 0;
		}
		21% {
			transform: scale(1);
			opacity: 0;
		}
	}

	.splash :global(.buttonGroup > div > button) {
		position: relative;
/*		overflow: hidden;*/
		overflow: visible;
	}

	/*.splash :global(.buttonGroup > div > button:first-child:after) {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
   	transform: translateX(100%);
   	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 60%);
   	animation: shine 6s infinite 2s;
	}*/

	.splash :global(.buttonGroup > div > button:first-child:last-child:after) {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border-radius: 4px;
		border: 2px solid var(--accentColor, #77b82a);
   	animation: pulse 6s infinite 2s;
   	transform-origin: 50% 50%;
   	transition-timing-function: ease-out;
	}

</style>

<div id="splash" class="splash" in:fly={{ duration: 300, y: 10, delay: 200 }}>

	<div
		class="splash_inner {$splash.fgImagePresentation}"
		class:logo={$splash.fgImage?.type == 'logo'}
		use:cssVars={styles}
	>
		<div bind:clientHeight={containerHeight}>
			{#if $splash.fgImage && $splash.fgImage.filename}
				<div class="fgImage" style="--mh:{mh}px">
					<div>
						{#if $splash.fgImage.format == 'svg'}
							<img
								id="i_splash_fg"
								src="https://cdn.attendzen.io/{$event.accountRef}/{$splash.fgImage.filename}"
								crossorigin="anonymous"
								alt=""
							/>
						{:else}
							<img
								id="i_splash_fg"
								src="https://cdn.attendzen.io/{$event.accountRef}/trim_{$splash.fgImage.filename}"
								crossorigin="anonymous"
								alt=""
							/>
						{/if}
					</div>
				</div>
			{/if}
			{#if $splash?.content?.EN?.html?.trim() != ''}
				<div bind:clientHeight={textHeight}>
					<div class="textcontent">
						{@html $splash?.content?.EN?.html}
					</div>
				</div>
			{/if}
		</div>
	</div>

	<div class="actions">
		<ButtonGroup wide={false}>
			<Button label="Check in" on:click={setScreen} double={true}>
				<svg viewBox="0 0 80 80"><path d="M28.8 59.6l-12.6-12.6 4.3-4.2 8.3 8.3 30.7-30.7 4.3 4.2z"/></svg>
			</Button>
			{#if $event?.setup?.checkin?.allowRegistrations && $settings.allowRegistrations && !$offline && walkInsAvailable()}
				<Button label="Register as a new attendee" shortlabel="Register" double={true} ghost={true} on:click={() => { $showRegform = true }}>
					<svg viewBox="0 0 80 80"><path d="M34.5 67.8l-7.1-8.4 1.5-1.3c.7-.6 1.2-1.5 1.3-2.4v-.9l-.1-.9 3.7-1.7.3 1.4c.2.8.2 1.6.2 2.4-.1 1.3-.6 2.6-1.3 3.7l2.1 2.5 24-20.2-.9-1.1 3.8-1.8 2.8 3.4-30.3 25.3zM62 35.9l-.4-.8-3.1-6.6-.4-.8-.1-.3-.3.1-.7.4-.7.3c-.5.2-1 .3-1.5.3-.4 0-.8-.1-1.2-.2-.9-.3-1.6-1-2-1.9-.4-.9-.4-1.8-.1-2.7.3-.9 1-1.6 1.9-2l.7-.3.8-.4.3-.1-.1-.3-.4-.8-3-6.5-.4-.8-.3-.3-.3.1-.8.4-33.6 15.7-.8.4-.3.1.1.3.4.8 3.1 6.6.4.8.1.3.3-.1.8-.4.7-.3c.5-.2 1-.3 1.5-.3.4 0 .8.1 1.2.2.9.3 1.6 1 2 1.9.4.9.4 1.8.1 2.7-.3.9-1 1.6-1.9 2l-.7.3-.6.3-.3.1.1.3.4.8 3 6.5.4.8.1.3.3-.1.8-.4 33.6-15.7.8-.4.3-.1-.2-.2zm-33.8 12l-1-2.2c1.1-.8 1.9-1.9 2.3-3.2.7-1.8.6-3.8-.2-5.6-.8-1.8-2.3-3.1-4.1-3.8-.8-.3-1.6-.4-2.5-.4-.5 0-1 .1-1.5.2l-1.1-2.3 29.1-13.6 1 2.2c-1.1.8-1.9 1.9-2.3 3.2-.7 1.8-.6 3.8.2 5.6.8 1.8 2.3 3.1 4.1 3.8.8.3 1.6.4 2.5.4.5 0 1-.1 1.5-.2l1.1 2.3-29.1 13.6z"/></svg>
				</Button>
			{/if}
		</ButtonGroup>
	</div>

</div>