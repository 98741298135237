import { get } from 'svelte/store';

import axios from "axios";

const baseURL = ((window.location.hostname == 'localhost') || (window.location.hostname == 'checkin-dev.attendzen.io')) ? 'https://api-dev.attendzen.io/' : 'https://api.attendzen.io/';

const debug = true;

import { offline, csrfToken, unlockCode, deviceRef, bigError, busy } from './stores.js';

const prelude = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	withCredentials: true
});

export const getServerData = async (endpoint, params) => {

	try {

		if (debug) console.log('GET', endpoint, new Date().toISOString());

		const csrf = get(csrfToken);
		const unlock = get(unlockCode);
		const device = get(deviceRef);

		let headers = {
			'X-CSRF-TOKEN': csrf,
			'X-UNLOCK-CODE': unlock,
			'X-DEVICE-REF': device
		};

		const authToken = sessionStorage.getItem('authToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		const response = await prelude({
			url: endpoint,
			method: "GET",
			headers: headers,
			params: params
		});

		if (debug) console.log('data', 'GET', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		return response.data;

	} catch (err) {

		if (err.response && err.response.status == 401) {

			unlockCode.set('');
			busy.set(false);

		} else {

			if (debug) console.log('prelude error', err.response.status);
			bigError.set("There was an error communicating with the check-in service.");

		}

	}

}

export const postServerData = async (endpoint, params) => {

	// TODO:
	// if offline, pass to offline log...

	let formData = new FormData();

	if (params) {
		for (let [key, value] of Object.entries(params)) {
			formData.append(key, value);
		}
	}

	if (!(params && params.dt)) {
		const dt = new Date().toISOString().replace('Z',''); // UTC
		formData.append('dt', dt);
	}

	try {

		if (debug) console.log('POST', endpoint, new Date().toISOString());

		const csrf = get(csrfToken);
		const unlock = get(unlockCode);
		const device = get(deviceRef);

		let headers = {
			'X-CSRF-TOKEN': csrf,
			'X-UNLOCK-CODE': unlock,
			'X-DEVICE-REF': device
		};

		const authToken = sessionStorage.getItem('authToken');
		if (authToken) headers['Authorization'] = 'Bearer ' + authToken;

		const response = await prelude({
			url: endpoint,
			method: "POST",
			headers: headers,
			data: formData
		});

		if (debug) console.log('data', 'POST', endpoint, response.data, new Date().toISOString());

		if (response.data.error) {
			bigError.set(response.data.error);
		}

		return response.data;

	} catch (err) {

		if (err.response && err.response.status == 401) {

			unlockCode.set('');
			busy.set(false);

		} else {

			if (debug) console.log('prelude error', err.response.status);
			bigError.set("There was an error communicating with the check-in service.");

		}

	}

}

export const getAuth = async (unlock) => {

	try {

		const device = get(deviceRef);

		let formData = new FormData();
		formData.append('unlockCode', unlock);

		if (device) {
			formData.append('deviceRef', device);
		}

		const response = await prelude({
			url: '/checkin/auth',
			method: "POST",
			data: formData
		});

		if (debug) console.log(response.data);

		if (response.data) {

			if (response.data.error) {

				return false;

			} else {

				unlockCode.set(unlock);

				if (response.data.authToken) {
					// only in dev, to help test in Safari
					sessionStorage.setItem('authToken', response.data.authToken);
				}

				if (response.data.csrfToken) {
					csrfToken.set(response.data.csrfToken);
				}

				if (response.data.deviceRef) {

					deviceRef.set(response.data.deviceRef);

					// Request persistent storage for site
					if (navigator.storage && navigator.storage.persist) {
						const isPersisted = await navigator.storage.persist();
						// console.log(`Persisted storage granted: ${isPersisted}`);
					}

				}

				return true;

			}

		}

		// // token expires in 2 days; refresh after 1
		// setTimeout(getAuth, 1000 * 60 * 60 * 24);

		return false;

	} catch (err) {

		if (debug) console.log('prelude error', err.response.status);
		bigError.set("There was an error communicating with the check-in service.");

	}

}