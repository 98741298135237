<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { event, attendee } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	const flexAlignments = {
		left: 'flex-start',
		center: 'center',
		right: 'flex-end'
	};

	onMount(() => {
		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';
		cVars.alignment = c.alignment;
		cVars.flexAlignment = flexAlignments[c.alignment];
		cVars.verticalAlignment = c.verticalAlignment;
		cVars.photoWidth = c.width ? c.width : '15mm';
		mounted = true;
	});

</script>

{#if mounted}
	<section class="component { c.component }" use:cssVars={cVars}>
		<div>
			<p>
				{#if $attendee.avatar}
					<img
						src="https://cdn.attendzen.io/{$event.accountRef}/thumb_{$attendee.avatar}"
						crossorigin="anonymous"
						alt=""
					/>
				{/if}
			</p>
		</div>
	</section>
{/if}