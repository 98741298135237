<script>

	import { fade } from 'svelte/transition';

	import Button from './ui/Button.svelte';

	import { event, settings, showSettings, showRegform, offline, logoSize, unbranded } from './lib/stores.js';
	import { modal } from './lib/stores.js';
	import { isDesktop } from './lib/device.js';

	let accountRef = $event?.accountRef;
	let logo = $event?.theme?.checkin?.logo;
	let logoStyle = $event?.theme?.checkin?.logoStyle;
	let eventName = $event?.name;

	let logoSvg = false;
	let mask = null;

	// let wide = false;
	// let extrawide = false;

	// let aspectRatio = logo ? logo.aspectRatio : '0';

	if (logo) {

		logoSvg = (logo.format == 'svg') ? true : false;

		// wide = false;
		// extrawide = false;

		// if (aspectRatio) {
		// 	if (aspectRatio < 15) {
		// 		extrawide = true;
		// 	} else if (aspectRatio < 60) {
		// 		wide = true;
		// 	}
		// }

		if (logoStyle == 'tinted') {
			if (logoSvg) {
				const regex = /\.svg$/;
				mask = 'https://cdn.attendzen.io/' + accountRef + '/mask_' + logo.filename.replace(regex, '.png');
			} else {
				const regex = /\.jpe?g$/;
				mask = 'https://cdn.attendzen.io/' + accountRef + '/mask_' + logo.filename.replace(regex, '.png');
			}
		} else {
			mask = null;
		}

	}

	function closePanels() {
		$showSettings = false;
		$showRegform = false;
	}

	function setLogoSize() {
		if (!$settings.staffed && ($event.theme?.checkin?.logoSize)) {
			$logoSize = $event.theme.checkin.logoSize;
		} else {
			$logoSize = 'small';
		}
	}

	function offlineModal() {
		let details = [
			'You can continue to check in attendees, and the device will re-sync with the cloud service when back online.'
		];
		if ($settings.printer) {
			details.push('On-demand badge printing is unavailable from this device while you’re offline.');
			if (isDesktop()) {
				details.push('However, you may print a simplified version of badges on a printer connected to this computer.');
			}
		}
		$modal = {
			message: 'Your device is offline.',
			details: details,
			opts: [
				{
					name: "OK",
					display: {
						mini: true,
						ghost: true
					}
				},
			]
		};
	}

	$: setLogoSize($event, $settings);

</script>

<style>

	.brand {
		box-sizing: border-box;
		position: absolute;
/*		top: 1.8rem;*/
		top: max(1.8rem, calc(0.5rem + env(safe-area-inset-top)));
		left: 0;
		width: 100%;
		height: 2.5rem;
		font-size: 1.8rem;
		text-align: left;
		box-sizing: border-box;
		padding-right: 6rem;
		transition: top 0.4s ease, opacity 0.3s ease;
		transition-delay: 0.2s;
	}

	:global(.unbranded) .brand {
		top: -2rem;
		opacity: 0;
	}

	:global(.logo-medium) .brand {
		height: 3.5rem;
	}

	:global(.logo-large) .brand {
		height: 4.5rem;
	}

	.brand button {
		position: absolute;
		top: 0;
		left: 0;
		border: 0;
		background: transparent;
		padding: 0;
		max-width: 100%;
		height: 100%;
	}

	.brand img {
		max-height: 100%;
	}

	.brand img.svg {
		height: 100%;
	}

	@supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) {
		.mask > button {
			position: relative;
			display: inline-flex;
		}
		.mask > button:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			-webkit-mask-image: var(--mask);
			-webkit-mask-size: contain;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: 0 0;
			mask-image: var(--mask);
			mask-size: contain;
			mask-repeat: no-repeat;
			mask-position: 0 0;
			background: var(--logoColor, #000);
			pointer-events: none;
		}
		.mask > button > img {
			visibility: hidden;
		}
	}

	.utils {
		position: absolute;
/*		top: 1.4rem;*/
		top: max(1.4rem, calc(0.2rem + env(safe-area-inset-top)));
		right: -0.35rem;
		display: flex;
		gap: 1rem;
		align-items: center;
		z-index: 10000;
	}

</style>

{#if $event.ref}
	{#if mask}
		<p
			class="brand mask"
			style="--mask:url('{mask}');"
			in:fade
		>
			<button type="button" id="homelink" on:click={closePanels}>
				<img
					src="{mask}"
					crossorigin="anonymous"
					alt="{eventName}"
					class:svg={logoSvg}
				/>
			</button>
		</p>
	{:else}
		<p
			class="brand"
			in:fade
		>
			<button type="button" id="homelink" on:click={closePanels}>
				{#if logo && logoSvg}
					<img
						class="svg"
						src="https://cdn.attendzen.io/{accountRef}/{logo.filename}"
						crossorigin="anonymous"
						alt="{eventName}"
					/>
				{:else if logo}
					<img
						src="https://cdn.attendzen.io/{accountRef}/small_{logo.filename}"
						crossorigin="anonymous"
						alt="{eventName}"
					/>
				{:else}
					<span>{eventName}</span>
				{/if}
			</button>
		</p>
	{/if}

	<div class="utils">
		{#if $settings.staffed && $offline}
			<Button subtle={true} red={true} on:click={offlineModal}>
				<svg viewBox="0 0 80 80"><path d="M69.9 43.7c-.2-6.3-4.9-11.5-11.1-12.1.6-8.2-6.1-15.1-14.4-15.1-3.4 0-6.5 1.2-9 3.1l2.3 3.3c1.9-1.5 4.2-2.3 6.7-2.3 3 0 5.8 1.2 7.8 3.4 1.9 2.1 2.9 4.7 2.7 7.5-.2 2.2 1.4 4.1 3.6 4.3 4.1.4 7.3 3.9 7.4 8.2.1 2.5-.9 5.1-2.5 6.7-1.3 1.3-2.9 2-4.9 2l2.6 3.7c5.9-1.5 9-7.3 8.8-12.7zM60.2 61.8l-32.2-45.9c-.6-.9-1.9-1.1-2.8-.5s-1.1 1.9-.5 2.8l3.4 4.8c-4.7.4-8.7 4.1-9.2 9.1-5.2 1.5-8.9 6.2-8.9 11.6 0 5.7 3.8 12.6 12.1 13h29.4l5.3 7.5c.4.6 1 .9 1.6.9.4 0 .8-.1 1.1-.4 1.1-.7 1.3-2 .7-2.9zm-37.9-9.2c-6.5-.4-8.2-6-8.2-9 0-3.5 2.5-6.7 6-7.7 1.6-.5 2.7-1.8 2.9-3.5.3-3.1 3-5.5 6.1-5.5.6 0 1.2.1 1.8.3.1 0 .2 0 .3.1l17.7 25.3h-26.6z"/></svg>
			</Button>
		{/if}
		<Button subtle={true} on:click={() => { $showSettings = true }}>
			<svg viewBox="0 0 80 80"><path d="M39.99 50.26c-5.65 0-10.25-4.6-10.25-10.25s4.6-10.25 10.25-10.25 10.25 4.6 10.25 10.25c0 5.66-4.6 10.25-10.25 10.25zm0-16.5c-3.45 0-6.25 2.8-6.25 6.25s2.8 6.25 6.25 6.25 6.25-2.8 6.25-6.25-2.81-6.25-6.25-6.25zM28.45 21.13l4.85 3.86c.91-.4 1.85-.73 2.8-.97 1.94-.48 3.95-.61 5.92-.37l3.48-5.08 5.08 2-.92 6.14c1.14.83 2.23 1.76 3.16 2.91 1 1.23 1.7 2.56 2.29 3.95l6.2.47.81 5.4-5.72 2.26c-.3 2.94-1.44 5.77-3.26 8.2l2.67 5.58-4.27 3.4-4.86-3.86c-.92.41-1.86.73-2.82.97-1.94.48-3.94.61-5.9.37l-3.48 5.07-5.08-2 .92-6.11c-1.15-.84-2.24-1.77-3.18-2.93-1-1.23-1.7-2.57-2.3-3.96l-6.18-.48-.81-5.4 5.7-2.24c.31-2.95 1.43-5.79 3.28-8.23l-2.67-5.57 4.29-3.38m-.97-3.88c-.54.14-1.06.39-1.52.75l-4.27 3.4c-1.46 1.16-1.92 3.18-1.12 4.85l1.69 3.53c-1.03 1.77-1.79 3.67-2.25 5.64l-3.6 1.41c-1.74.68-2.77 2.47-2.49 4.31l.81 5.4c.28 1.85 1.79 3.25 3.65 3.4l3.89.3c.54.98 1.12 1.85 1.79 2.67.59.73 1.24 1.41 1.99 2.08l-.58 3.84c-.28 1.84.75 3.64 2.49 4.32l5.08 2c.8.32 1.64.35 2.43.16.92-.23 1.76-.79 2.34-1.62l2.2-3.22c1.62 0 3.24-.21 4.84-.61.38-.09.75-.2 1.13-.31l3.07 2.45c1 .79 2.28 1.05 3.47.75.54-.14 1.06-.39 1.52-.75l4.27-3.4c1.46-1.16 1.92-3.18 1.12-4.85l-1.7-3.54c1.02-1.77 1.78-3.66 2.24-5.62l3.62-1.42c1.74-.68 2.77-2.47 2.49-4.31l-.81-5.4c-.28-1.85-1.79-3.25-3.65-3.4l-3.92-.3c-.54-.98-1.12-1.84-1.78-2.66-.58-.72-1.24-1.4-1.97-2.07l.58-3.88c.28-1.84-.75-3.64-2.49-4.32l-5.08-2c-.8-.32-1.64-.35-2.43-.16-.92.23-1.76.79-2.34 1.62l-2.21 3.22c-1.63 0-3.25.21-4.86.61-.37.09-.74.19-1.1.3l-3.07-2.45c-1.01-.76-2.29-1.02-3.47-.72z"/></svg>
		</Button>
	</div>

{/if}