<script>

	import { autoblur } from '../lib/autoblur.js';
	import { isNarrowScreen	} from '../lib/stores.js';

	export let type = "button";
	export let form = undefined;
	export let label = '';
	export let shortlabel = '';
	export let red = false;
	export let green = false;
	export let ghost = false;
	export let disabled = false;
	export let wide = false;
	export let double = false;
	export let mini = false;
	export let subtle = false;

	if (type != "submit") form = null;

</script>

<style>

	button {
		display: inline-block;
		box-sizing: border-box;
		padding: 1rem 1.8rem;
		border-radius: 4px;
		border: 1px solid var(--accentColor, #77b82a);
		background: var(--accentColor, #77b82a);
		color: var(--buttonTextColor, #fff);
		font-size: 1rem;
		cursor: pointer;
		transition: transform 0.1s ease;
		min-width: 12rem;
		width: min-content;
		white-space: nowrap;
	}

	@media (max-width: 640px) {
		button {
			min-width: 9rem;
			/*font-size: 0.8rem;*/
			padding: 0.8rem;
		}
	}

	button.icon {
		width: 2rem;
		height: 2rem;
		background: var(--textColor);
/*		border-color: var(--blend-x);*/
		border-color: transparent;
		color: var(--blend-x);
		border-radius: 100%;
		padding: 0;
		display: inline-grid;
		place-content: center;
		min-width: unset;
	}

	button.double {
		width: 26rem;
		max-width: 100%;
	}

	button.wide {
		width: 100%;
		min-width: 100%;
	}

	button.red {
		border-color: var(--errorColor, #d63430);
		background-color: var(--errorColor, #d63430);
	}

	button.green {
		border-color: var(--successColor, #77b82a);
		background-color: var(--successColor, #77b82a);
	}

	button.ghost {
		background: transparent;
		border-color: var(--accentColor);
		color: var(--accentColor);
	}

	button.ghost.red {
		background: transparent;
		border-color: var(--errorColor, #d63430);
		color: var(--errorColor, #d63430);
	}

	button.ghost.green {
		background: transparent;
		border-color: var(--successColor, #77b82a);
		color: var(--successColor, #77b82a);
	}

	button:active,
	button:focus {
		border: 1px solid var(--accentBlend-120);
		background: var(--accentBlend-120);
		/*box-shadow: 0 0 0 1px var(--accentBlend-120);*/
	}

	@media (hover: hover) and (pointer: fine) {
		button:hover {
			border: 1px solid var(--accentBlend-120);
			background: var(--accentBlend-120);
		}
	}

	button.ghost:active,
	button.ghost:focus {
		background: transparent;
		border: 1px solid var(--textColor);
		color: var(--textColor);
	}

	@media (hover: hover) and (pointer: fine) {
		button.ghost:hover {
			background: transparent;
			border: 1px solid var(--textColor);
			color: var(--textColor);
		}
	}

	button.red:active,
	button.red:focus {
		background: var(--errorBlend-120);
		border: 1px solid var(--errorBlend-120);
		color: var(--buttonTextColor);
	}

	@media (hover: hover) and (pointer: fine) {
		button.red:hover {
			background: var(--errorBlend-120);
			border: 1px solid var(--errorBlend-120);
			color: var(--buttonTextColor);
		}
	}

	button.red.ghost:active,
	button.red.ghost:focus {
		background: var(--blend-05);
		border: 1px solid var(--errorBlend-120);
		color: var(--errorBlend-120);
	}

	@media (hover: hover) and (pointer: fine) {
		button.red.ghost:hover {
			background: var(--blend-05);
			border: 1px solid var(--errorBlend-120);
			color: var(--errorBlend-120);
		}
	}

	button.green:active,
	button.green:focus {
		background: var(--successBlend-120);
		border: 1px solid var(--successBlend-120);
		color: var(--buttonTextColor);
	}

	@media (hover: hover) and (pointer: fine) {
		button.green:hover {
			background: var(--successBlend-120);
			border: 1px solid var(--successBlend-120);
			color: var(--buttonTextColor);
		}
	}

	button.green.ghost:active,
	button.green.ghost:focus {
		background: var(--blend-05);
		border: 1px solid var(--successBlend-120);
		color: var(--successBlend-120);
	}

	@media (hover: hover) and (pointer: fine) {
		button.green.ghost:hover {
			background: var(--blend-05);
			border: 1px solid var(--successBlend-120);
			color: var(--successBlend-120);
		}
	}

	button[disabled] {
		border: 1px solid var(--blend-20) !important;
		background: var(--blend-20) !important;
		color: var(--bgColor) !important;
		cursor: not-allowed !important;
	}

	button.ghost[disabled] {
		border: 1px solid var(--blend-40) !important;
		color: var(--blend-40) !important;
	}

	button span {
		display: inline-block;
		vertical-align: middle;
		font-weight: 400;
	}

	/*button.ghost span {
		font-weight: 400;
	}*/

	button :global(svg) {
		display: inline-block;
		width: 2em;
		height: 2em;
		vertical-align: middle;
	}

	button.subtle {
		padding: 0;
		background: transparent;
		color: var(--blend-20);
		border: none;
		font-size: 0.8rem;
		font-weight: 400;
	}

	button.subtle:active,
	button.subtle:focus {
		color: var(--textColor);
	}

	@media (hover: hover) and (pointer: fine) {
		button.subtle:hover {
			color: var(--textColor);
		}
	}

	button.subtle.red {
		color: var(--errorColor);
	}

	button.subtle.red:active,
	button.subtle.red:focus {
		background: transparent;
		border: none;
	}

	@media (hover: hover) and (pointer: fine) {
		button.subtle.red:hover {
			background: transparent;
			border: none;
			color: var(--errorBlend-120);
		}
	}

	button.mini {
		/*padding: 0.25rem 1rem;
		font-size: 0.75rem;*/
		min-width: unset;
	}

	/*:global(button) + button {
		margin-left: 0.5rem;
	}*/

	button.subtle.icon {
		padding-right: 0.25rem;
	}

	button.subtle :global(svg) {
		width: 1.8rem;
		height: 1.8rem;
	}

</style>

<button
	{type}
	{disabled}
	{form}
	class:red
	class:green
	class:ghost
	class:wide
	class:double
	class:mini
	class:subtle
	class:icon={$$slots.default && !label}
	use:autoblur
	on:click
>
	{#if $$slots.default}
		<slot/>
	{/if}
	{#if shortlabel && $isNarrowScreen}
		<span>{shortlabel}</span>
	{:else if label}
		<span>{label}</span>
	{/if}
</button>