<script>

	import { slide } from 'svelte/transition';

	import { v4 as uuid } from 'uuid';

	import TextField from './fields/TextField.svelte';
	import TextArea from './fields/TextArea.svelte';
	import SelectList from './fields/SelectList.svelte';
	import RadioButtons from './fields/RadioButtons.svelte';
	import EmailField from './fields/EmailField.svelte';
	import Checkbox from './fields/Checkbox.svelte';
	import Switch from './fields/Switch.svelte';
	import NumberField from './fields/NumberField.svelte';
	import UrlField from './fields/UrlField.svelte';
	import SocialMediaUrlField from './fields/SocialMediaUrlField.svelte';
	import DateField from './fields/DateField.svelte';
	import CheckboxGroup from './fields/CheckboxGroup.svelte';
	import SessionChooser from './fields/SessionChooser.svelte';

	export let ref;
	export let label;
	export let component;
	export let value = undefined;
	export let options = [];
	export let nullOption = undefined;
	export let placeholder = undefined;
	export let notes = undefined;
	export let inputmode = undefined;
	export let err = null;
	export let maxlength = undefined;
	export let disabled = false;
	export let errIgnoresFocus = false;

	// console.log('field',component,value);

	let inFocus = false;

	if (!ref) ref = 'field-' + uuid();

	let transitionDuration = 200;
	let transitionDelay = transitionDuration - 10;

	let componentLookup = {
		TextField: TextField,
		TextArea: TextArea,
		SelectList: SelectList,
		RadioButtons: RadioButtons,
		EmailField: EmailField,
		Checkbox: Checkbox,
		Switch: Switch,
		NumberField: NumberField,
		UrlField: UrlField,
		SocialMediaUrlField: SocialMediaUrlField,
		Country: SelectList,
		DateField: DateField,
		CheckboxGroup: CheckboxGroup,
		SessionChooser: SessionChooser,
	};

</script>

<style>

	.err,
	.notes {
		padding: 0.5rem 0;
		border: 0;
	}

	.err p,
	.notes p {
		position: relative;
		padding-left: 1.475rem;
		line-height: 1.2;
		text-align: left;
		font-size: 0.8rem;
		line-height: 1.3;
	}

	.err p {
		color: var(--errorColor);
	}

	.notes p {
		color: var(--blend-60);
	}

	.err svg,
	.notes svg {
		position: absolute;
		/*top: 0.1rem;*/
		top: 0;
		left: 0;
		width: 0.95rem;
	}

	path {
		fill: currentColor;
	}

	.field {
		margin: 1rem 0;
		display: flex;
		gap: 0.8rem 2rem;
		flex-wrap: wrap;
		padding-top: 1rem;
	}

	:global(form) > .field:first-child {
		margin-top: 0;
		padding-top: 0;
	}

	.field.Switch {
		align-items: center;
	}

	.labelgroup {
		flex: 0 0 16rem;
		font-size: 1rem;
		padding: 1rem 0 0 0;
		margin: 0;
		display: flex;
		justify-content: space-between;
		line-height: 1;
		color: var(--labelColor);
	}

	.field.Switch .labelgroup {
		padding-top: 0;
	}

	.inputwrapper {
		flex: 1 0 auto;
		min-width: 55%;
	}

	.field.Switch .inputwrapper {
		display: flex;
		gap: 2rem;
		align-items: center;
	}

	.counter {
		font-size: 0.625rem;
		color: var(--red);
	}

</style>

<div class="field {component}">

	{#if label && (component != 'Checkbox')}
		<p class="labelgroup">	
			<label for={ref}>
				{label}
			</label>
			{#if (component != 'SessionChooser') && maxlength && value && ((maxlength - value.length) < 10)}
				<span class="counter">{maxlength - value.length}</span>
			{/if}
		</p>
	{/if}

	<div class="inputwrapper">

		<svelte:component
			this={componentLookup[component]}
			{ref}
			bind:value
			bind:err
			bind:inFocus
			{inputmode}
			{label}
			{placeholder}
			{options}
			{nullOption}
			{maxlength}
			{disabled}
		/>

		<slot/>

		{#if err && (!inFocus || errIgnoresFocus)}
			<div class="err" in:slide|local={{ duration: transitionDuration }} out:slide|local={{ delay: transitionDelay, duration: transitionDuration }}>
				<p>
					<svg viewBox="0 0 56 56"><path d="M3.73 52.65c-1.25-.03-2.45-.69-3.12-1.85-.67-1.16-.64-2.53-.04-3.63l24.26-42.04c.65-1.07 1.83-1.78 3.17-1.78 1.34 0 2.52.71 3.17 1.78l24.27 42.04c.6 1.1.63 2.47-.04 3.63-.67 1.16-1.87 1.82-3.12 1.85h-48.55zm24.27-43.08l-22.06 38.21h44.12l-22.06-38.21zM28 40.31c1.48 0 2.7 1.22 2.7 2.7s-1.22 2.7-2.7 2.7-2.7-1.22-2.7-2.7 1.22-2.7 2.7-2.7zm-2.33-18.91c0-1.54.9-2.49 2.33-2.49s2.33.95 2.33 2.49v14.43c0 1.54-.9 2.49-2.33 2.49s-2.33-.95-2.33-2.49v-14.43z"/></svg>
					<span>{err}</span>
				</p>
			</div>
		{/if}

		{#if notes}
			<div class="notes" in:slide|local={{ duration: transitionDuration }} out:slide|local={{ duration: transitionDuration }}>
				<p>
					<svg viewBox="0 0 56 56"><path d="M56 28c0 15.46-12.54 28-28 28s-28-12.54-28-28 12.54-28 28-28 28 12.54 28 28zm-30.09-13.59c-.08 2.4 1.29 3.85 4.18 3.85 3.45 0 5.47-2.34 5.47-4.86 0-2.02-.9-3.79-3.95-3.79-4.01 0-5.7 2.78-5.7 4.8zm10.49 25.16l-1.18-1.58c-2.01 1.59-4.06 2.34-4.69 2.34-.49 0-.69-.63-.21-2.46l2.74-10.36c1.04-3.79.69-6.26-1.39-6.26-2.49 0-8.32 2.48-13.38 6.71l1.04 1.7c1.6-1.08 4.3-2.16 4.93-2.16.48 0 .41.63 0 2.21l-2.39 9.86c-1.46 5.55.07 6.82 2.15 6.82 2.07 0 7.45-1.89 12.38-6.82z"/></svg>
					<span>{notes}</span>
				</p>
			</div>
		{/if}

	</div>

</div>

