<script>

	import { onMount, tick } from 'svelte';
	import { writable } from 'svelte/store';
	import { slide } from 'svelte/transition';

	import { busy, bigError, event } from '../../lib/stores.js';

	import { getServerData } from '../../lib/prelude.js';
	import { autoblur } from '../../lib/autoblur.js';

	export let value;
	export let maxlength;

	const tz = $event.tz;

	let trackNames = {};
	let trackIndexes = {};
	let options = [];

	let mounted = false;

	let agendaHasMultipleDays = false;

	onMount(async () => {

		$busy = true;

		if (!$bigError) {

			$busy = false;

			setSessions();

			if (!value) {
				value = (maxlength == 1) ? null : [];
			}

			mounted = true;

		}

	});

	function setSessions() {

		console.log($event.agenda.tracks, $event.agenda.sessions);

		$event.agenda.tracks.forEach((t,i) => {
			trackNames[t.ref] = t.name;
			trackIndexes[t.ref] = i;
		});

		let sessions = [];

		for (const [r,s] of Object.entries($event.agenda.sessions)) {
			s.ref = r;
			sessions.push(s);
		}

		options = sessions.sort(function(a,b){
			return a.e - b.e || trackIndexes[a.track] - trackIndexes[b.track];
		});

		let days = [];

		options.forEach(s => {
			if (!days.includes(s.d)) {
				days.push(s.d);
			}
		});

		if (days.length > 1) {
			agendaHasMultipleDays = true;
		}

		mounted = true;

	}

</script>

<style>
	.options {
		position: relative;
		margin-top: 0.6rem;
		padding-bottom: 1rem;
	}

	.options > div {
		padding: 0.4rem 0;
		display: flex;
		gap: 1rem;
		font-size: 0.9rem;
		line-height: 1.4;
	}

	.choose .checkbox,
	.choose .radiobutton {
		display: block;
		position: relative;
		width: 1rem;
		height: 1rem;
		top: 0.1rem;
	}

	.options label {
		display: flex !important;
		gap: 1rem;
		line-height: 1.4 !important;
		padding: 0 !important;
	}

	.time {
		width: 2.6rem;
		font-variant-numeric: tabular-nums;
		opacity: 0.8;
	}

	.session {
		display: block;
		font-weight: bold;
	}

	.track {
		display: block;
		font-weight: normal;
		opacity: 0.8;
	}

	.none {
		padding: 0.4rem 0;
		font-size: 0.9rem;
		line-height: 1.4;
		opacity: 0.8;
	}

	.options > div + .day {
		margin-top: 1rem;
	}

	.day {
		font-size: 0.9rem;
		padding-bottom: 0.4rem;
		border-bottom: 1px solid var(--boxBorderColor);
	}

	.choose {
		position: relative;
	}

	.checkbox {
		width: 1.2em;
		height: 1.2em;
		position: absolute;
		top: 0.2em;
		left: 0;
		overflow: visible;
	}

	.checkbox input {
		position: absolute;
		inset: 0;
		padding: 0;
		margin: 0;
		opacity: 0;
		z-index: 2;
	}

	.checkbox svg {
		position: absolute;
		inset: 0;
		opacity: 1;
		z-index: 1;
	}

	.checkbox path {
		fill: currentColor;
		transition: opacity 0.2s ease-in-out;
	}

	.checkbox path.on-tick {
		fill: var(--bgColor);
	}

	.checkbox input + svg .on {
		opacity: 0;
	}

	.checkbox input:checked + svg .on {
		opacity: 1;
	}

	.radiobutton {
		width: 1.2em;
		height: 1.2em;
		position: relative;
		overflow: visible;
		top: -0.1em;
	}
	.radiobutton input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		opacity: 0;
		z-index: 2;
	}
	.radiobutton svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		z-index: 1;
	}
	.radiobutton path,
	.radiobutton circle {
		fill: currentColor;
		transition: opacity 0.2s ease-in-out;
	}
	.radiobutton circle.on-bg {
		fill: transparent;
	}
	.radiobutton input + svg .on {
		opacity: 0;
	}
	.radiobutton input:checked + svg .on {
		opacity: 1;
	}
</style>

<div class="options">
	{#each options as s, i}
		{#if agendaHasMultipleDays}
			{#if (i == 0) || (s.d != options[i-1].d)}
				<p class="day">
					{s.d}
				</p>
			{/if}
		{/if}
		<div>
			<div class="choose">
				{#if maxlength == 1}
					<span class="radiobutton">
						<input
							type="radio"
							id="option_{s.ref}"
							bind:group={value}
							value={s.ref}
						/>
						<svg viewBox="0 0 60 60"><g class="on"><circle class="on-bg" cx="30" cy="30" r="27.42"/><circle class="on-disc" cx="29.94" cy="29.99" r="15"/></g><path class="off" d="M30 0c16.54 0 30 13.46 30 30s-13.46 30-30 30-30-13.46-30-30 13.46-30 30-30zm0 56c14.34 0 26-11.66 26-26s-11.66-26-26-26-26 11.66-26 26 11.66 26 26 26z"/></svg>
					</span>
				{:else}
					<span class="checkbox">
						<input
							type="checkbox"
							id="option_{s.ref}"
							name="session"
							bind:group={value}
							value={s.ref}
						/>
						<svg viewBox="0 0 50 50"><g class="on"><path class="on-bg" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99z"/><path class="on-tick" d="M20.33 38.22l-12.21-12.21 4.24-4.24 7.97 7.97 17.2-17.2 4.24 4.25z"/></g><path class="off" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99zm44 42c1.1 0 2-.9 2-2v-38.01c0-1.1-.9-2-2-2h-38c-1.1 0-2 .9-2 2v38.01c0 1.1.9 2 2 2h38z"/></svg>
					</span>
				{/if}
			</div>
			<label for="option_{s.ref}">
				<span class="time">
					{s.t}
				</span>
				<span class="session">
					{s.name}
					{#if s.track}
						<span class="track">
							{trackNames[s.track]}
						</span>
					{/if}
				</span>
			</label>
		</div>
	{:else}
		<p class="none">None available</p>
	{/each}
</div>